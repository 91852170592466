import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";
import { api, url } from "../axios";
import Checkbox from "@mui/material/Checkbox";
import { Button, FormControlLabel, TextField } from "@mui/material";
import { format, formatDate } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

export default function UdyamInfo() {
  const [fields, setFields] = useState([]);
  const [isBusy, setIsbusy] = useState(false);
  const [tempData, setTempData] = useState({ submitEdit: true });
  const params = useParams();

  const getData = async (data) => {
    if (data !== "blank") {
      setIsbusy(true);
      api.get(`api/udyam/search/${data}`).then((res) => {
        let data = res.data;
        data.edit_models = data.edit_models.map((edit) => {
          return {
            approver_id: edit.approver_id,
            created_at: edit.created_at,

            id: edit.id,

            is_approved: Boolean(edit.is_approved),

            model_data: JSON.parse(edit.model_data),

            model_data_before: JSON.parse(edit.model_data_before),

            model_edited: edit.model_edited,
            remarks:edit.remarks
          };
        });
        data.units = data.unit_with_full_details;
        delete data.unit_with_full_details;
        console.log(data)
        setTempData({ ...data, ...{ submitEdit: true } });
        setIsbusy(false);
      });
    }
  };

  const handleEdit = (data, field) => {};

  const handleRemoveField = async (field, index) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].filter((_, i) => i !== index),
    });
  };

  const handleAddField = (field) => {
    setTempData({
      ...tempData,
      [field]: tempData[field].concat({}),
    });
  };

  useEffect(() => {
    getData(params.id);
  }, []);

  return (
    <div className="md:mx-20 mx-3 my-10">
      {isBusy ? (
        <Hourglass
          visible={true}
          height="80"
          width="80"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={["#306cce", "#72a1ed"]}
        />
      ) : (
        <div className="mt-5  grid gap-x-4">
          <div className="mt-3 text-center sm:mt-5">
            {/* <div className=" sm:flex sm:flex-row items-center justify-center w-full">
            <div className="text-lg font-light ">
              {tempData?.submitEdit ? (
                <div className="w-full flex gap-x-3 ">
                  <TextField
                    label="Udyam Registration Number"
                    className="xl:w-96"
                    size="small"
                    required
                    variant="outlined"
                    value={tempData.udyam_id}
                    onChange={(e) =>
                      setTempData({
                        ...tempData,
                        udyam_id: e.target.value,
                      })
                    }
                  />
                  {tempData?.id && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setTempData({
                          ...tempData,
                          submitEdit: !tempData.submitEdit,
                        });
                      }}
                      color="error"
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              ) : (
                <div className="w-full ">
                  <Button
                    className=""
                    onClick={() => {
                      setTempData({
                        ...tempData,
                        submitEdit: !tempData.submitEdit,
                      });
                    }}
                    variant="contained"
                  >
                    Edit Data
                  </Button>
                  <div
                    className="mt-5 w-full"
                    style={{ width: "70vw" }}
                  >
                    Remarks
                    {tempData?.remarks?.map((remark, index) => {
                      return (
                        <div
                          className="border w-full px-5 py-3 rounded-md"
                          key={remark.id}
                        >
                          <div
                            className="col-span-5  text-lg text-left"
                            style={{
                              fontSize: "15px",
                              width: "100% important",
                            }}
                          >
                            {remark.remarks}
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="text-xs text-left">
                              {remark.user?.name}
                            </div>
                            <div className="text-xs text-right">
                              {formatDate(
                                new Date(remark.created_at),
                                "dd-MM-yy hh:mm a"
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div> */}
            {tempData?.submitEdit ? (
              <div>
                <div className="w-full grid md:grid-cols-2 gap-5 ">
                  <TextField
                    label="Udyam Registration Number"
                    className="w-full"
                    size="small"
                    required
                    variant="outlined"
                    value={tempData.udyam_id}
                    onChange={(e) =>
                      setTempData({
                        ...tempData,
                        udyam_id: e.target.value,
                      })
                    }
                  />

                  <div className="flex justify-center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        getData(tempData.udyam_id);
                      }}
                      style={{ width: "50%" }}
                      color="primary"
                    >
                      Retrieve
                    </Button>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                  <div className="w-full">
                    <TextField
                      label="Type of Enterprise"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          type_of_enterprise: e.target.value,
                        })
                      }
                      value={tempData?.type_of_enterprise ?? ""}
                    />
                  </div>
                  <div className="">
                    <TextField
                      label="Type of Organisation"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          type_of_organisation: e.target.value,
                        })
                      }
                      value={tempData.type_of_organisation ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Owner Name"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          owner_name: e.target.value,
                        })
                      }
                      value={tempData.owner_name ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Do you have GSTin"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          gstin: e.target.value,
                        })
                      }
                      value={tempData.gstin ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Email"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          email: e.target.value,
                        })
                      }
                      value={tempData.email ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Gender"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          gender: e.target.value,
                        })
                      }
                      value={tempData.gender ?? ""}
                    />
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={
                        tempData.date_of_incorporation
                          ? new dayjs(tempData.date_of_incorporation)
                          : null
                      }
                      format="DD-MM-YYYY"
                      slotProps={{ textField: { size: "small" } }}
                      label="Date of Incorporation"
                      onChange={(newValue) =>
                        setTempData({
                          ...tempData,
                          date_of_incorporation: format(
                            new Date(newValue.$d),
                            "yyyy-MM-dd"
                          ),
                        })
                      }
                      className=""
                    />
                  </LocalizationProvider>
                  <div className="w-full">
                    <TextField
                      label="Major Activity"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          major_activity: e.target.value,
                        })
                      }
                      value={tempData.major_activity ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Name of Enterprise"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          name_of_enterprise: e.target.value,
                        })
                      }
                      value={tempData.name_of_enterprise ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="PAN"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          pan: e.target.value,
                        })
                      }
                      value={tempData.pan ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Mobile"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          mobile: e.target.value,
                        })
                      }
                      value={tempData.mobile ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Social Category"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          social_category: e.target.value,
                        })
                      }
                      value={tempData.social_category ?? ""}
                    />
                  </div>
                  <div className="w-full">
                    <TextField
                      label="Specially Disabled/DIVYANG"
                      className="w-full"
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        setTempData({
                          ...tempData,
                          divyag: e.target.value,
                        })
                      }
                      value={tempData.divyag ?? ""}
                    />
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={
                        tempData.date_of_incorporation
                          ? new dayjs(tempData.date_of_commencement)
                          : null
                      }
                      format={
                        tempData?.date_of_commencement ? "DD-MM-YYYY" : ""
                      }
                      slotProps={{ textField: { size: "small" } }}
                      label="Date of Commencement of Production/Business"
                      onChange={(newValue) =>
                        setTempData({
                          ...tempData,
                          date_of_commencement: format(
                            new Date(newValue.$d),
                            "yyyy-MM-dd"
                          ),
                        })
                      }
                      className=""
                    />
                  </LocalizationProvider>
                </div>
              </div>
            ) : (
              ""
            )}
            {tempData.submitEdit ? (
              <div className="mt-2">
                <div className=" my-10">
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12"></div>
                    {tempData.bank_detail && (
                      <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Bank Details
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-10">
                          <div className="w-full">
                            <TextField
                              label="Name"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                setTempData({
                                  ...tempData,
                                  bank_detail: {
                                    ...tempData.bank_detail,
                                    name: e.target.value,
                                  },
                                })
                              }
                              value={tempData.bank_detail?.name}
                            />
                          </div>
                          <div className="">
                            <div className="w-full">
                              <TextField
                                label="IFSC"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setTempData({
                                    ...tempData,
                                    bank_detail: {
                                      ...tempData.bank_detail,
                                      ifsc: e.target.value,
                                    },
                                  })
                                }
                                value={tempData.bank_detail?.ifsc}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="w-full">
                              <TextField
                                label="Account No"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) =>
                                  setTempData({
                                    ...tempData,
                                    bank_detail: {
                                      ...tempData.bank_detail,
                                      account_no: e.target.value,
                                    },
                                  })
                                }
                                value={tempData.bank_detail?.account_no}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {tempData.employment_detail && (
                      <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          Employment Details
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-4 mt-10 gap-3">
                          <div className="w-full">
                            <TextField
                              label="Male"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                setTempData({
                                  ...tempData,
                                  employment_detail: {
                                    ...tempData.employment_detail,
                                    male: e.target.value,
                                  },
                                })
                              }
                              value={tempData.employment_detail?.male}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Female"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                setTempData({
                                  ...tempData,
                                  employment_detail: {
                                    ...tempData.employment_detail,
                                    female: e.target.value,
                                  },
                                })
                              }
                              value={tempData.employment_detail?.female}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Others"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) =>
                                setTempData({
                                  ...tempData,
                                  employment_detail: {
                                    ...tempData.employment_detail,
                                    other: e.target.value,
                                  },
                                })
                              }
                              value={tempData.employment_detail?.other}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Total"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              disabled
                              value={
                                Number.parseInt(
                                  tempData.employment_detail?.male
                                ) +
                                Number.parseInt(
                                  tempData.employment_detail?.female
                                ) +
                                Number.parseInt(
                                  tempData.employment_detail?.other
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Investment in Plant and Machinery OR Equipment (in Rs.)
                        <Button onClick={() => handleAddField("investments")}>
                          <PlusCircleIcon className="h-7 w-7" />
                        </Button>
                      </h2>
                      {tempData?.investments?.map((investment, index) => (
                        <div
                          className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-10 pl-10 relative"
                          key={index}
                        >
                          <div className="absolute top-0 font-bold text-xl">
                            {index + 1}
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Financial Year"
                              className="w-full"
                              size="small"
                              required
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].financial_year =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.financial_year}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Enterprise Type"
                              className="w-full"
                              required
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].enterprise_type =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.enterprise_type}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              required
                              label="Written Down Value"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].wdv = e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.wdv}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              required
                              label=" Exclusion of cost"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].exclusion_of_cost =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.exclusion_of_cost}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              required
                              label="Net Investment in Plant and Machinery/Equipment"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].net_investment =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.net_investment}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              required
                              label="Total Turnover"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].total_turnover =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.total_turnover}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              required
                              label="Export Turnover"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].export_turnover =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.export_turnover}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              required
                              label="Net Turnover"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].net_turnover =
                                  e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.net_turnover}
                            />
                          </div>
                          <FormControlLabel
                            label="Is ITR Filled?"
                            control={
                              <Checkbox
                                checked={investment.is_itr_filled === 1}
                                onChange={() => {
                                  const newInvestments = [
                                    ...tempData.investments,
                                  ];
                                  newInvestments[index].is_itr_filled =
                                    investment.is_itr_filled === 1 ? 0 : 1;
                                  setTempData({
                                    ...tempData,
                                    investments: newInvestments,
                                  });
                                }}
                              />
                            }
                          />
                          <div className="w-full">
                            <TextField
                              label="ITR Type"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newInvestments = [
                                  ...tempData.investments,
                                ];
                                newInvestments[index].itr_type = e.target.value;
                                setTempData({
                                  ...tempData,
                                  investments: newInvestments,
                                });
                              }}
                              value={investment.itr_type}
                            />
                          </div>
                          <div className="w-full md:col-span-2">
                            <Button
                              onClick={() =>
                                handleRemoveField("investments", index)
                              }
                              variant="contained"
                              color="error"
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Unit(s) Details
                        <Button onClick={() => handleAddField("units")}>
                          <PlusCircleIcon className="h-7 w-7" />
                        </Button>
                      </h2>
                      {tempData?.units?.map((unit, index) => {
                        return (
                          <div
                            key={unit.id}
                            className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 relative pl-10 rounded-md"
                            style={{
                              borderWidth: "1px",
                              padding: "40px",
                              marginTop: index > 0 ? "40px" : "20px",
                            }}
                          >
                            {unit.deleted_at && (
                              <div className="bg-white px-2 text-red-500 absolute font-bold text-xl -top-3.5 right-1/2 translate-x-1/2">
                                Deleted
                              </div>
                            )}
                            <div
                              className="absolute font-bold text-xl left-3"
                              style={{ marginTop: "40px" }}
                            >
                              {index + 1}
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Unit Name"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].name = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.name}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Flat"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].flat = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.flat}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Building"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].building = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.building}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Village/Town"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].village = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.village}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Block"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].block = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.block}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Road"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].road = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.road}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="City"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].city = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.city}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Pin"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].pin = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.pin}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Latitude"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].latitude = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.latitude}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="Longitude"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].longitude = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.longitude}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="State"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].state = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.state}
                              />
                            </div>
                            <div className="w-full">
                              <TextField
                                required
                                label="District"
                                className="w-full"
                                size="small"
                                variant="outlined"
                                onChange={(e) => {
                                  const newUnits = [...tempData.units];
                                  newUnits[index].district = e.target.value;
                                  setTempData({
                                    ...tempData,
                                    units: newUnits,
                                  });
                                }}
                                value={unit.district}
                              />
                            </div>
                            {unit?.files && (
                              <div className="">
                                <div className="font-bold">Images</div>
                                <div
                                  className="grid  gap-3 p-2 rounded-md"
                                  style={{ border: "1px solid #ccc" }}
                                >
                                  {unit.files.map((image, index) => (
                                    <div
                                      className="w-full rounded-md "
                                      key={index}
                                    >
                                      <img
                                        src={url + image.link}
                                        alt="verification"
                                        className="object-contain rounded-md"
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {unit?.verifies_with_all_details && (
                              <div>
                                <div className="font-bold ">
                                  Unit Verification Details
                                </div>
                                {unit.verifies_with_all_details.map(
                                  (verify, index) => (
                                    <div
                                      className="w-full text-left grid gap-5 p-4 rounded-md"
                                      style={{ border: "1px solid #ccc" }}
                                      key={verify.id}
                                    >
                                      <div className="w-full">
                                        Status:{" "}
                                        {`${verify.status[0].toUpperCase()}${verify.status.substring(1).replaceAll("_", " ")}`}
                                      </div>
                                      {verify?.user && (
                                        <div className="w-full">
                                          Verification staff: {verify.user.name}
                                        </div>
                                      )}
                                      {verify?.district_user && (
                                        <div className="w-full">
                                          District Lead:{" "}
                                          {verify.district_user.name}
                                        </div>
                                      )}
                                      {verify?.m_and_e_user && (
                                        <div className="w-full">
                                          M&E: {verify.m_and_e_user.name}
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                            <div className="w-full md:col-span-2">
                              <Button
                                onClick={() =>
                                  handleRemoveField("units", index)
                                }
                                variant="contained"
                                color="error"
                              >
                                Remove
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {tempData?.official_address && (
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                        Official address of Enterprise
                      </h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3">
                        <div className="w-full">
                          <TextField
                            label="Flat/Door/Block No"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  flat: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.flat}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Village/Town"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  village: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.village}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Road/Street/Lane"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  road: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.road}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="State"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  state: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.state}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Mobile"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  mobile: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.mobile}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Name of Premises/Building"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  name: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.name}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Block"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  block: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.block}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="City"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  city: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.city}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="District"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  district: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.district}
                          />
                        </div>
                        <div className="w-full">
                          <TextField
                            label="Email"
                            className="w-full"
                            size="small"
                            variant="outlined"
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                official_address: {
                                  ...tempData.official_address,
                                  email: e.target.value,
                                },
                              });
                            }}
                            value={tempData?.official_address?.email}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 mt-5 text-gray-900">
                      National Industry Classification Code(S)
                      <Button onClick={() => handleAddField("nics")}>
                        <PlusCircleIcon className="h-7 w-7" />
                      </Button>
                    </h2>
                    {tempData?.nics?.map((nic, index) => {
                      return (
                        <div
                          key={index}
                          className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-3 pl-10 relative"
                        >
                          <div className="absolute text-xl font-bold">
                            {index + 1}
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Nic 5 Digit"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newNics = [...tempData.nics];
                                newNics[index].nic_id = e.target.value;
                                setTempData({
                                  ...tempData,
                                  nics: newNics,
                                });
                              }}
                              value={nic.nic_id}
                            />
                          </div>
                          <div className="w-full">
                            <TextField
                              label="Main Activity"
                              className="w-full"
                              size="small"
                              variant="outlined"
                              onChange={(e) => {
                                const newNics = [...tempData.nics];
                                newNics[index].activity = e.target.value;
                                setTempData({
                                  ...tempData,
                                  nics: newNics,
                                });
                              }}
                              value={nic.activity}
                            />
                          </div>
                          <div className="w-full md:col-span-2">
                            <Button
                              onClick={() => handleRemoveField("nics", index)}
                              variant="contained"
                              color="error"
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="w-full mt-10 grid grid-cols-1">
                    <div>
                      <FormControlLabel
                        className="w-full"
                        label=" Are you interested to get registered on
                    Government e-Market (GeM) Portal"
                        control={
                          <Checkbox
                            checked={tempData.gem_portal === 0}
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                gem_portal: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        className="w-full"
                        label="Are you interested to get registered on TReDS
                  Portals(one or more) "
                        control={
                          <Checkbox
                            checked={tempData.treds_portal === 1}
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                treds_portal: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        className="w-full"
                        label="Are you interested to get registered on NCS
                  Portals(one or more) "
                        control={
                          <Checkbox
                            checked={tempData.ncs_portal === 1}
                            onChange={(e) => {
                              setTempData({
                                ...tempData,
                                ncs_portal: e.target.checked ? 1 : 0,
                              });
                            }}
                          />
                        }
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-5">
                      <TextField
                        label="DIC"
                        className="w-full"
                        size="small"
                        variant="outlined"
                        onChange={(e) =>
                          setTempData({
                            ...tempData,
                            dic: e.target.value,
                          })
                        }
                        value={tempData.dic ?? ""}
                      />
                      <TextField
                        label="MSME DI"
                        className="w-full"
                        size="small"
                        variant="outlined"
                        onChange={(e) =>
                          setTempData({
                            ...tempData,
                            msme_di: e.target.value,
                          })
                        }
                        value={tempData.msme_di ?? ""}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={
                            tempData.date_of_registration
                              ? new dayjs(tempData.date_of_registration)
                              : null
                          }
                          format="DD-MM-YYYY"
                          slotProps={{
                            textField: { size: "small" },
                          }}
                          label="Udyam Registration Date"
                          onChange={(newValue) =>
                            setTempData({
                              ...tempData,
                              date_of_registration: format(
                                new Date(newValue.$d),
                                "yyyy-MM-dd"
                              ),
                            })
                          }
                          className=""
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="border-b border-gray-900/10 mt-7">
                    {tempData?.verifies_with_all_details && (
                      <div>
                        <div className="font-bold">
                          Udyam Verification Details
                        </div>
                        {tempData.verifies_with_all_details.map(
                          (verify, index) => (
                            <div
                              className="w-full text-left grid gap-5 p-4 rounded-md"
                              style={{ border: "1px solid #ccc" }}
                              key={verify.id}
                            >
                              <div className="w-full">
                                Status:{" "}
                                {`${verify.status[0].toUpperCase()}${verify.status.substring(1).replaceAll("_", " ")}`}
                              </div>
                              {verify?.user && (
                                <div className="w-full">
                                  Verification staff: {verify.user.name}
                                </div>
                              )}
                              {verify?.district_user && (
                                <div className="w-full">
                                  District Lead: {verify.district_user.name}
                                </div>
                              )}
                              {verify?.m_and_e_user && (
                                <div className="w-full">
                                  M&E: {verify.m_and_e_user.name}
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  {tempData.edit_models?.length && (
                    <div className="mt-4">
                      <div className="font-bold">Edit and Remarks</div>
                      {tempData?.edit_models.map((edit) => (
                        <div key={edit.id} className="p-2 rounded-md" style={{ border: "1px solid #ccc" }}>
                          <div className="mt-3 text-center sm:mt-5 ">

                            {!((Object.keys(edit.model_data).length===3) && Object.keys(edit.model_data.nics).length===0 && Object.keys(edit.model_data.units).length===0  && Object.keys(edit.model_data.investments).length===0 ) && <div className="border p-3 rounded-md">
                              {edit?.model_data_before &&
                                Object.keys(edit?.model_data_before).filter(
                                  (e) =>
                                    ![
                                      "nics",
                                      "investments",
                                      "units",
                                      "bank_detail",
                                      "official_address",
                                      "employment_detail",
                                    ].includes(e)
                                ).length > 0 && (
                                  <div className=" mt-4">
                                    <div className="font-bold underline">
                                      Udyam Details
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-3">
                                      <div className="border rounded-md p-2 mt-2">
                                        Change From
                                        {Object.keys(edit?.model_data_before)
                                          .filter(
                                            (e) =>
                                              ![
                                                "nics",
                                                "investments",
                                                "units",
                                                "bank_detail",
                                                "employment_detail",
                                                "official_address",
                                              ].includes(e)
                                          )
                                          .map((detail) => {
                                            return (
                                              <div
                                                className="border rounded-md p-2 mt-2"
                                                key={detail}
                                              >
                                                <div
                                                  className="grid grid-cols-5"
                                                  key={detail}
                                                >
                                                  <div className="text-left col-span-2">
                                                    {detail[0].toUpperCase() +
                                                      detail
                                                        .slice(1)
                                                        .replaceAll(
                                                          "_",
                                                          " "
                                                        )}{" "}
                                                  </div>
                                                  <div className="text-left  font-normal col-span-3">
                                                    {
                                                      edit?.model_data_before?.[
                                                        detail
                                                      ]
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div className="border rounded-md p-2 mt-2">
                                        Change To
                                        {Object.keys(edit?.model_data)
                                          .filter(
                                            (e) =>
                                              ![
                                                "nics",
                                                "investments",
                                                "units",
                                                "bank_detail",
                                                "employment_detail",
                                                "official_address",
                                              ].includes(e)
                                          )
                                          .map((detail) => {
                                            return (
                                              <div
                                                className="border rounded-md p-2 mt-2"
                                                key={detail}
                                              >
                                                <div
                                                  className="grid grid-cols-5"
                                                  key={detail}
                                                >
                                                  <div className="text-left col-span-2">
                                                    {detail[0].toUpperCase() +
                                                      detail
                                                        .slice(1)
                                                        .replaceAll(
                                                          "_",
                                                          " "
                                                        )}{" "}
                                                  </div>
                                                  <div className="text-left col-span-3 font-normal">
                                                    {edit?.model_data?.[detail]}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {tempData?.model_data_before?.units.length >
                                0 && (
                                <div className=" mt-4">
                                  {/* Units */}
                                  <div className="font-bold underline">
                                    Units
                                  </div>
                                  <div className="grid md:grid-cols-2 gap-3">
                                    <div className="border rounded-md p-2 mt-2">
                                      Change From
                                      {tempData?.model_data_before?.units?.map(
                                        (unit) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={unit.id}
                                            >
                                              {Object.keys(unit)
                                                .filter(
                                                  (e) =>
                                                    ![
                                                      "created_at",
                                                      "deleted_at",
                                                      "updated_at",
                                                      "entity_id",
                                                    ].includes(e)
                                                )
                                                .map((key) => {
                                                  return (
                                                    <div
                                                      className="grid grid-cols-3"
                                                      key={key}
                                                    >
                                                      <div className="text-left">
                                                        {key[0].toUpperCase() +
                                                          key.slice(1)}{" "}
                                                      </div>{" "}
                                                      <div className="text-left col-span-2 font-normal">
                                                        {unit[key]}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="border rounded-md p-2 mt-2">
                                      Change To
                                      {tempData?.model_data?.units?.map(
                                        (unit) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={unit.id}
                                            >
                                              {Object.keys(unit).map((key) => {
                                                return (
                                                  <div
                                                    className="grid grid-cols-3"
                                                    key={key}
                                                  >
                                                    <div className="text-left">
                                                      {key[0].toUpperCase() +
                                                        key.slice(1)}{" "}
                                                    </div>{" "}
                                                    <div className="text-left col-span-2 font-normal">
                                                      {unit[key]}
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {tempData?.model_data_before?.investments.length >
                                0 && (
                                <div className=" mt-4">
                                  {/* Units */}
                                  <div className="font-bold underline">
                                    Investments
                                  </div>
                                  <div className="grid md:grid-cols-2 gap-3">
                                    <div className="border rounded-md p-2 mt-2">
                                      Change From
                                      {tempData?.model_data_before?.investments?.map(
                                        (unit) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={unit.id}
                                            >
                                              {Object.keys(unit)
                                                .filter(
                                                  (e) =>
                                                    ![
                                                      "created_at",
                                                      "deleted_at",
                                                      "updated_at",
                                                      "entity_id",
                                                    ].includes(e)
                                                )
                                                .map((key) => {
                                                  return (
                                                    <div
                                                      className="grid grid-cols-3"
                                                      key={key}
                                                    >
                                                      <div className="text-left">
                                                        {(
                                                          key[0].toUpperCase() +
                                                          key.slice(1)
                                                        ).replaceAll("_", " ")}
                                                      </div>{" "}
                                                      <div className="text-left col-span-2 font-normal">
                                                        {key === "is_itr_filled"
                                                          ? unit[key]
                                                            ? "Yes"
                                                            : "No"
                                                          : unit[key]}
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="border rounded-md p-2 mt-2">
                                      Change To
                                      {tempData?.model_data?.investments?.map(
                                        (unit) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={unit.id}
                                            >
                                              {Object.keys(unit).map((key) => {
                                                return (
                                                  <div
                                                    className="grid grid-cols-3"
                                                    key={key}
                                                  >
                                                    <div className="text-left">
                                                      {(
                                                        key[0].toUpperCase() +
                                                        key.slice(1)
                                                      ).replaceAll("_", " ")}
                                                    </div>{" "}
                                                    <div className="text-left col-span-2 font-normal">
                                                      {key === "is_itr_filled"
                                                        ? unit[key]
                                                          ? "Yes"
                                                          : "No"
                                                        : unit[key]}
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {tempData?.model_data_before?.nics?.length >
                                0 && (
                                <div className=" mt-4">
                                  <div className="font-bold underline">NIC</div>
                                  <div className="grid md:grid-cols-2 gap-3">
                                    <div className="border rounded-md p-2 mt-2">
                                      Change From
                                      {tempData?.model_data_before?.nics?.map(
                                        (unit) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={unit.id}
                                            >
                                              {Object.keys(unit).map((key) => {
                                                return (
                                                  <div
                                                    className="grid grid-cols-3"
                                                    key={key}
                                                  >
                                                    <div className="text-left">
                                                      {key[0].toUpperCase() +
                                                        key.slice(1)}{" "}
                                                    </div>{" "}
                                                    <div className="text-left col-span-2 font-normal">
                                                      {unit[key]}
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="border rounded-md p-2 mt-2">
                                      Change To
                                      {tempData?.model_data?.nics?.map(
                                        (unit) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={unit.id}
                                            >
                                              {Object.keys(unit).map((key) => {
                                                return (
                                                  <div
                                                    className="grid grid-cols-3"
                                                    key={key}
                                                  >
                                                    <div className="text-left">
                                                      {key[0].toUpperCase() +
                                                        key.slice(1)}{" "}
                                                    </div>{" "}
                                                    <div className="text-left col-span-2 font-normal">
                                                      {unit[key]}
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {tempData?.model_data_before?.bank_detail &&
                                Object.keys(
                                  tempData?.model_data_before?.bank_detail
                                ).length > 0 && (
                                  <div className=" mt-4">
                                    <div className="font-bold underline">
                                      Bank Details
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-3">
                                      <div className="border rounded-md p-2 mt-2">
                                        Change From
                                        {Object.keys(
                                          tempData?.model_data_before
                                            ?.bank_detail
                                        )?.map((detail) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={detail}
                                            >
                                              <div
                                                className="grid grid-cols-3"
                                                key={detail}
                                              >
                                                <div className="text-left">
                                                  {detail[0].toUpperCase() +
                                                    detail
                                                      .slice(1)
                                                      .replace("_", " ")}{" "}
                                                </div>
                                                <div className="text-left col-span-2 font-normal">
                                                  {
                                                    tempData?.model_data_before
                                                      ?.bank_detail[detail]
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="border rounded-md p-2 mt-2">
                                        Change To
                                        {Object.keys(
                                          tempData?.model_data?.bank_detail
                                        )?.map((detail) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={detail}
                                            >
                                              <div
                                                className="grid grid-cols-3"
                                                key={detail}
                                              >
                                                <div className="text-left">
                                                  {detail[0].toUpperCase() +
                                                    detail
                                                      .slice(1)
                                                      .replace("_", " ")}{" "}
                                                </div>
                                                <div className="text-left col-span-2 font-normal">
                                                  {
                                                    tempData?.model_data
                                                      ?.bank_detail[detail]
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {tempData?.model_data_before?.employment_detail &&
                                Object.keys(
                                  tempData?.model_data_before?.employment_detail
                                ).length > 0 && (
                                  <div className=" mt-4">
                                    <div className="font-bold underline">
                                      Employee Details
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-3">
                                      <div className="border rounded-md p-2 mt-2">
                                        Change From
                                        {Object.keys(
                                          tempData?.model_data_before
                                            ?.employment_detail
                                        )?.map((detail) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={detail}
                                            >
                                              <div
                                                className="grid grid-cols-3"
                                                key={detail}
                                              >
                                                <div className="text-left">
                                                  {detail[0].toUpperCase() +
                                                    detail
                                                      .slice(1)
                                                      .replace("_", " ")}{" "}
                                                </div>
                                                <div className="text-left col-span-2 font-normal">
                                                  {
                                                    tempData?.model_data_before
                                                      ?.employment_detail[
                                                      detail
                                                    ]
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="border rounded-md p-2 mt-2">
                                        Change To
                                        {Object.keys(
                                          tempData?.model_data
                                            ?.employment_detail
                                        )?.map((detail) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={detail}
                                            >
                                              <div
                                                className="grid grid-cols-3"
                                                key={detail}
                                              >
                                                <div className="text-left">
                                                  {detail[0].toUpperCase() +
                                                    detail
                                                      .slice(1)
                                                      .replace("_", " ")}{" "}
                                                </div>
                                                <div className="text-left col-span-2 font-normal">
                                                  {
                                                    tempData?.model_data
                                                      ?.employment_detail[
                                                      detail
                                                    ]
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {tempData?.model_data_before?.official_address &&
                                Object.keys(
                                  tempData?.model_data_before?.official_address
                                ).length > 0 && (
                                  <div className=" mt-4">
                                    <div className="font-bold underline">
                                      Official Address
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-3">
                                      <div className="border rounded-md p-2 mt-2">
                                        Change From
                                        {Object.keys(
                                          tempData?.model_data_before
                                            ?.official_address
                                        )?.map((detail) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={detail}
                                            >
                                              <div
                                                className="grid grid-cols-3"
                                                key={detail}
                                              >
                                                <div className="text-left">
                                                  {detail[0].toUpperCase() +
                                                    detail
                                                      .slice(1)
                                                      .replace("_", " ")}{" "}
                                                </div>
                                                <div className="text-left col-span-2 font-normal">
                                                  {
                                                    tempData?.model_data_before
                                                      ?.official_address[detail]
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <div className="border rounded-md p-2 mt-2">
                                        Change To
                                        {Object.keys(
                                          tempData?.model_data?.official_address
                                        )?.map((detail) => {
                                          return (
                                            <div
                                              className="border rounded-md p-2 mt-2"
                                              key={detail}
                                            >
                                              <div
                                                className="grid grid-cols-3"
                                                key={detail}
                                              >
                                                <div className="text-left">
                                                  {detail[0].toUpperCase() +
                                                    detail
                                                      .slice(1)
                                                      .replace("_", " ")}{" "}
                                                </div>
                                                <div className="text-left col-span-2 font-normal">
                                                  {
                                                    tempData?.model_data
                                                      ?.official_address[detail]
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>}
                          </div>
                          <div
                            className="rounded-md mt-5 p-4"
                            style={{ borderWidth: "1px" }}
                          >
                            <div>
                              Status :{" "}
                              {edit.is_approved && edit.approver_id
                                ? "Approved"
                                : !edit.is_approved && edit.approver_id
                                  ? "Rejected"
                                  : "Pending"}
                            </div>
                          </div>
                          <div
                            className="rounded-md mt-5 p-4"
                            style={{ borderWidth: "1px" }}
                          >
                            <div>Remarks</div>

                            {edit?.remarks?.map((remark) => (
                              <div className="" key={remark.id}>
                                <div
                                  className="p-2 "
                                  style={{ borderWidth: "1px" }}
                                >
                                  <div className="text-xs text-gray-500 flex justify-between">
                                    <div>{remark.user?.name}</div>
                                    <div className="">
                                      {format(
                                        new Date(remark.created_at),
                                        "dd-MMM-yy hh:mm a"
                                      )}
                                    </div>
                                  </div>
                                  <div className="text-left">{remark.remarks}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}
